// Customisations for client theme

// Colours

$primary: #0b375e;
$secondary: #a09e9e;

$light-grey: #d1d1d1;
$white: #fff;
$blue: #2c5697;

$theme-colors: (
  "navy": #0b375e,
  "blue": $blue,
  "red": #d66057,
  "yellow": #ecc559,
  "orange": rgb(247, 141, 78),
  "green": #5ab85b,
  "cyan": #4bc0de,
  "primary": #0b375e,
  "secondary": #a09e9e,
  "light": #d1d1d1,
  "dark": #5a5a5a,
  "gray": #a09e9e,
  "gray-dark": #5a5a5a,
);

// Fonts

$font-size-base: 1rem; // 1rem = 16
$h1-font-size: 36px;
$h2-font-size: 20px;
$small-font-size: 75%; // (1rem = 16) = 12

// This does not seem to be applied, but we force below.
$table-head-color: $primary;

// Not an official colour, but this appears to be the background
$body-bg: rgb(236, 236, 236);

@import "~bootstrap/scss/bootstrap";

// Doesn't appear to be an option, so set manually.
label {
  color: $secondary;
}

// Force the text colour - the above is ignored it seems
th {
  color: $primary;
}

// Text of badges is auto selected by Bootstrap, we override it
.badge {
  color: $white;
}

// Navbar links:
// - Always grey
// - Divided by a grey line
// - have an blue underline when active
.navbar-nav .nav-link {
  border-left: 0.5px solid $secondary;
}

.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 3px solid $primary;
  color: $secondary;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: $primary;
}

// This applies to the dropdown when the menu is shown
.navbar-light .navbar-nav .show > .nav-link {
  color: $secondary;
}

// In the dropdown, the items are:
// - grey
// - separated by grey line
// - on hover, the item should be blue
.navbar-light .dropdown-item {
  color: $secondary;
  border-bottom: 0.5px solid $secondary;
  padding-top: 16px;
  padding-bottom: 16px;
}

.navbar-light .dropdown-item:last-child {
  border-bottom: 0;
}

.navbar-light .dropdown-item:hover {
  color: $primary;
  background-color: $white;
}

// Navbar has border around it, but it otherwise flush to page
.navbar {
  border-top: 0.5px solid $secondary;
  border-bottom: 0.5px solid $secondary;
  padding-top: 0;
  padding-bottom: 0;
}

// Our top nav bar is 64px high, and we
#navbar-nav .nav-link {
  height: 64px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

// Brand text matches the colour themes
.navbar-light .navbar-brand {
  color: $blue;
  font-weight: bolder;
}

.navbar-light .navbar-brand:hover {
  color: $primary;
}

// Our secondary buttons have white text (bootstrap algorithm decides black)
.btn-secondary {
  color: $white;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: $white;
  background-color: $primary;
}

// Headers have underlines

h1 {
  border-bottom: 1px solid $light-grey;
}

h2 {
  border-bottom: 1px solid $light-grey;
  margin-top: 3rem;
}

// Our toolbar needs gap between items
.toolbar > *:not(:last-child) {
  margin-right: 10px;
}

// Dropdown (SelectionButton)
// This is a simpler version of the navbar version.

.dropdown-item {
  color: $secondary;
}

.dropdown-item:hover {
  color: $primary;
  background-color: $white;
}

.dropdown-toggle:hover {
  color: $secondary;
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: $secondary;
  background-color: $white;
}

// It looks strange to have the toast vary in side, so pin to the maximum
.toast {
  min-width: $toast-max-width;
}

.accordion {
  margin-top: 40px;
  .card-header {
    border: none;
    h2 {
      background: url('assets/uparrow.png') no-repeat calc(100% - 10px) center;
      background-size: 20px;
      cursor: pointer;
      font-size: 18px;
      &.collapsed {
        background-image: url('assets/downarrow.png')
      }
    }
    &-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}